import { apiHeart } from '@/request/api';
import { getStorage } from '@/utils';

const DURATION = 1000 * 120;// 心跳间隔时长
let timer = null;

// 全局心跳-用于刷新token有效期
function heartbeatHandle(to) {
    if (timer) {
        clearInterval(timer);
        timer = null;
    }
    if (to.path?.slice(0, 5) === '/week') return;// 客户周报不需要心跳
    if (!getStorage('token')) return;// 未登录
    timer = setInterval(() => {
        heartbeatRequest();
    }, DURATION)
}

// 请求
function heartbeatRequest() {
    if (!getStorage('token')) return;// 未登录
    apiHeart();
}

export default heartbeatHandle;