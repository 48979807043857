export default [
    {
        path: '/egpm/material',
        name: 'EgpmMaterial',
        meta: { title: '原料风味EGPM' },
        component: () => import('../views/egpm-material/index.vue'),
        redirect: '/egpm/material/list',
        children: [
            {
                path: 'list',
                name: 'EgpmMaterialList',
                meta: { title: '原料风味EGPM' },
                component: () => import('../views/egpm-material/task-list/index.vue')
            },
            {
                path: 'download',
                name: 'EgpmMaterialDownload',
                meta: { title: '原料风味EGPM-下载记录' },
                component: () => import('../views/egpm-material/download/index.vue')
            },
            {
                path: 'config',
                name: 'EgpmMaterialConfig',
                meta: { title: '原料风味EGPM-配置' },
                component: () => import('../views/egpm-material/config/index.vue')
            },
            {
                path: 'create',
                name: 'EgpmMaterialCreate',
                meta: { title: '原料风味EGPM-创建任务' },
                component: () => import('../views/egpm-material/create-task/index.vue')
            },
            {
                path: 'details',
                name: 'EgpmMaterialDetails',
                meta: { title: '原料风味EGPM-任务详情' },
                component: () => import('../views/egpm-material/task-details/index.vue'),
                redirect: '/egpm/material/details/params',
                children: [
                    {
                        path: 'params',
                        name: 'EgpmMaterialDetailsParams',
                        meta: { title: '原料风味EGPM-任务参数' },
                        component: () => import('../views/egpm-material/task-details/params/index.vue'),
                    },
                    {
                        path: 'result',
                        name: 'EgpmMaterialDetailsResult',
                        meta: { title: '原料风味EGPM-任务结果' },
                        component: () => import('../views/egpm-material/task-details/result/index.vue'),
                        redirect: '/egpm/material/details/result/single',
                        children: [
                            {
                                path: 'single',
                                name: 'EgpmMaterialDetailsResultSingle',
                                meta: { title: '原料风味EGPM-任务结果-单期图表' },
                                component: () => import('../views/egpm-material/task-details/result/single/index.vue'),
                            },
                            {
                                path: 'area',
                                name: 'EgpmMaterialDetailsResultArea',
                                meta: { title: '原料风味EGPM-任务结果-地域分布' },
                                component: () => import('../views/egpm-material/task-details/result/area/index.vue'),
                            },
                            {
                                path: 'quarter',
                                name: 'EgpmMaterialDetailsResultQuarter',
                                meta: { title: '原料风味EGPM-任务结果-季度TOP' },
                                component: () => import('../views/egpm-material/task-details/result/quarter/index.vue'),
                            }
                        ]
                    }
                ]
            }
        ]
    }
]