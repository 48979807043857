import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './utils/version';// 版本更新

// ElementPlus
import './assets/css/element/index.scss';
import ElementPlus from 'element-plus';
import locale from 'element-plus/lib/locale/lang/zh-cn'; //中文

// vant
import 'vant/lib/index.css';

// if (process.env.NODE_ENV === 'development') {
//     require('@/mock')
// }

const app = createApp(App);
app.use(store);
app.use(router);
app.use(ElementPlus, { size: 'small', zIndex: 3000, locale });
app.mount('#app');