import { defineStore } from 'pinia';
import $router from '../router';

export default defineStore('useNavStore', {
    state: () => ({
        from: null,
        // 是否直接替换
        isReplace: false,
        // 已缓存页面
        navList: [{ meta: { title: '首页' }, path: '/home' }],
        // 最新增加的下标
        newIndex: null
    }),
    actions: {
        // 添加缓存导航
        addNav(to) {
            const i = this.navList.findIndex(item => item.path === to.path);
            if (i !== -1) {
                this.newIndex = i;
            } else if (this.isReplace) {
                const fIndex = this.navList.findIndex(item => item.path === this.from.path);
                this.navList.splice(fIndex, 1, to);
            } else {
                this.navList.push(to);
                this.newIndex = this.navList.length - 1;
            }
            this.isReplace = false;
        },
        // 删除nav
        delNav(index, nowPath) {
            const obj = this.navList.splice(index, 1);// 删除
            // 如果删除的nav是当前页，就跳转到首页
            if (obj[0]?.path === nowPath) {
                $router.push(this.navList[0]);
            }
        },
        // 修改最新一个的title
        updateTitle(path, tit) {
            const i = this.navList.findIndex(item => item.path === path);
            if (i === -1) return;
            this.navList[i].meta.title = tit;
        },
        // 重置
        reset() {
            this.navList = [{ meta: { title: '首页' }, path: '/home', fullPath: '/home' }];
        },
        setFrom(val) {
            this.from = val;
        },
        setIsReplace(val) {
            this.isReplace = val;
        }
    },
    persist: {
        enabled: true,// 刷新页面后是否缓存
        strategies: [
            {
                storage: sessionStorage
            }
        ]
    }
})