import { defineStore } from 'pinia';

export default defineStore('useKeepAlive', {
    state: () => ({
        keepRoutes: [],
    }),
    actions: {
        setKeepRoutes(data) {
            this.keepRoutes = data || [];
        }
    },
    persist: {
        enabled: false,// 刷新页面后是否缓存
        strategies: [
            {
                storage: sessionStorage
            }
        ]
    }
})