import { apiReportLogAdd } from '@/request/api';

// 需要心跳的路由matched[0]
const PATHS = [
    '/dish',
    '/brand',
    '/city',
    '/citygrid',
    '/materialflavor',
    '/dishsearch',
    '/assistant',
    '/egpm',
    '/egpm/material'
    // '/powerbi' // powerbi新增了单独的心跳
]
const DURATION = 1000 * 60;// 心跳间隔时长
let timer = null;


// 心跳
function heartbeatHandle(to) {
    if (timer) {
        clearInterval(timer);
        timer = null;
    }
    if (PATHS.includes(to.matched[0].path)) {
        timer = setInterval(() => {
            heartbeatRequest(to)
        }, DURATION)
    }
}

// 请求
function heartbeatRequest(to) {
    const params = {
        title: to.meta.title,
        path: to.path,
        reportId: to.query?.reportId || null,// 报告id
    }
    apiReportLogAdd(params);
}

export default heartbeatHandle;