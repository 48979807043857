<template>
    <router-view v-slot="{ Component }">
        <keep-alive :include="keepRoutes">
            <component :is="Component" />
        </keep-alive>
    </router-view>
</template>

<script setup>
import useKeepAlive from "@/store/useKeepAlive";
import { storeToRefs } from "pinia";

const keepAliveStore = useKeepAlive();
const { keepRoutes } = storeToRefs(keepAliveStore);
</script>

<style lang="scss">
@import "./assets/css/font.css"; // 字体
@import "./assets/css/style.scss"; // 全局样式
@import "./assets/css/variable.scss"; // 全局变量
// @import "//at.alicdn.com/t/c/font_4010425_qbpbrfhb0dj.css"; // iconfont
@import "./assets/icon/iconfont.css"; // iconfont
</style>
