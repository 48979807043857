/* 免登录白名单 path或者title */
export const routeWhites = [
    '/login',// 登录页
    '/login/index',// 登录页
    '/login/backpassword',// 找回密码
    '/week',// 客户周报
    '/week/list',// 客户周报列表
    '/week/report',// 客户周报列表
    '/week/plate',// 客户周报板块
    '/week/details',// 客户周报详情
    '餐饮资讯周报',
];

// 强制修改密码或绑定code与跳转路由映射
export const passportCodeMap = new Map([
    ['601', '/password'],// 初始化密码为临时密码，请立即修改密码！
    ['602', '/password'],// 您的密码长时间未修改，为了安全起见，请立即修改密码！
    ['603', '/bind'],// 手机号或邮箱未完善
])