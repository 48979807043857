/**
 * 公用方法
 */
/**
 * 本地缓存储存
 * @param {String} key [键名]
 * @param {*} val [值]
 */
export const setStorage = (key, val) => {
    if (typeof val !== 'string') {
        val = JSON.stringify(val)
    }
    window.localStorage.setItem(key, val)
}
/**
 * 获取本地缓存
 * @param {String} key [键名]
 */
export const getStorage = (key) => {
    return window.localStorage.getItem(key);
}
/**
 * 删除本地缓存
 * @param {String} key [键名]
 */
export const removeStorage = (key) => {
    window.localStorage.removeItem(key);
}
/**
 * 清空本地缓存
 */
export const clearStorage = () => {
    window.localStorage.clear();
    window.sessionStorage.clear();
}
/**
 * 防抖函数
 * @param func 需要包装的函数
 * @param delay 延迟时间，单位ms
 * @param immediate 是否默认执行一次(第一次不延迟)
 */
export const debounce = (func, delay, immediate = false) => {
    let timer = null
    return (...args) => {
        if (immediate) {
            func.apply(this, args) // 确保引用函数的指向正确，并且函数的参数也不变
            immediate = false
            return
        }
        clearTimeout(timer)
        timer = setTimeout(() => {
            func.apply(this, args)
        }, delay)
    }
}

/**
 * 超过多少位字符后用...代替
 * @param {string} str 字符串
 * @param {number} digit 保留位数
 * @returns {string}
 */
export const strEllipsis = (str = '', digit = 5) => {
    const len = str.length || 0;
    if (len > digit) {
        str = str.slice(0, digit) + '...'
    }
    return str
}

/**
 * 数字格式化
 * @param {number} val 值
 * @param {number} digit 保留位数
 * @param {boolean} mantissa 小于1w时是否保留小数
 */
export function numFormat(val = 0, digit = 2, mantissa = false) {
    val = Number(val);
    let res = val;
    if (val < 10000) {
        mantissa && (res = val.toFixed(digit))
    }
    if (val >= 10000 && val < 100000000) {
        res = (val / 10000).toFixed(digit) + '万'
    }
    if (val >= 100000000) {
        res = (val / 100000000).toFixed(digit) + '亿'
    }
    return res;
}

/**
 * File转base64
 * @param file 
 * @return base64
 * */
export function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        let fileResult = "";
        //开始转
        reader.onload = () => {
            fileResult = reader.result;
        };
        //转失败
        reader.onerror = (error) => {
            reject(error);
        };
        //结束 resolve
        reader.onloadend = () => {
            resolve(fileResult);
        };
        reader.readAsDataURL(file);
    });
}

/**
 * 数组分组
 * @param {array} arr // 需要分组的数组
 * @param {string | function} generateKey // 获取按哪个key分组的方法
 * @returns {object}
 */
export function groupBy(arr, generateKey) {
    if (typeof generateKey === 'string') {// 参数归一化
        const propName = generateKey;
        generateKey = (item) => item[propName];
    }
    const result = {};
    arr.forEach(item => {
        const key = generateKey(item);
        if (!result[key]) {
            result[key] = [];
        }
        result[key].push(item);
    });
    return result;
}

/**
 * 将元素滚动到底部
 * @param { string } selector css选择器
 */
export function scrollToBottom(selector) {
    const el = document.querySelector(selector);
    el.scrollTop = el.scrollHeight;
}

/**
 * 将html转为纯文本
 * @param { string } html
 */
export function html2text(html) {
    return html.replace(/<(style|script|iframe)[^>]*?>[\s\S]+?<\/\1\s*>/gi, '').replace(/<[^>]+?>/g, '').replace(/\s+/g, ' ').replace(/ /g, ' ').replace(/>/g, ' ');
}

/**
 * 数组对象按属性去重
 * @param { Array } arr 数组对象
 * @param { String } prop 属性名
 */
export function unique(arr, prop) {
    const res = new Map();
    return arr.filter((item) => !res.has(item[prop]) && res.set(item[prop], 1));
}