import { createRouter, createWebHashHistory } from 'vue-router';
import { getStorage } from '@/utils';
import useNavStore from '@/store/useNavStore';
import routes from './routes';
import routesWeek from './routesWeek';
import routesAi from './routesAi';
import routesEgpm from './routesEgpm';
import routesEgpmMaterial from './routesEgpmMaterial';
import routesEgpmBrand from './routesEgpmBrand';
import { ElMessage } from 'element-plus';
import heartbeatHandle from '@/utils/heartbeat';
import heartbeatGlobal from '@/utils/heartbeat-global';
import { routeWhites, passportCodeMap } from './routerConfig';

const router = createRouter({
    history: createWebHashHistory(),
    routes: [...routesWeek, ...routesAi, ...routesEgpm, ...routesEgpmMaterial, ...routesEgpmBrand, ...routes],
    scrollBehavior(to, from, savedPosition) {
        if (to.path === from.path) return;
        if (savedPosition) {
            return { ...savedPosition, behavior: 'instant' };
        } else {
            if (from.meta.saveSrollTop) {
                from.meta.savedPosition = document.documentElement.scrollTo || document.body.scrollTop;
            }
            return { left: 0, top: to.meta.savedPosition || 0, behavior: 'instant' }
        }
    }
})

// 判断用户绑定
function determineCode(to) {
    return new Promise((r) => {
        const passport_code = getStorage('passport_code');
        const passport_msg = getStorage('passport_msg');
        if (!passport_code) {
            r();
        } else {
            const target = passportCodeMap.get(passport_code);
            if (to.path !== target) {
                passport_msg && ElMessage.warning(passport_msg);
                r(target);
            } else {
                r();
            }
        }
    })
}

/* 路由拦截器 */
router.beforeEach(async (to, from, next) => {
    if (to.meta.title) document.title = to.meta.title;
    // 周报页面相关
    if (to.path.startsWith('/week')) {
        // 判断是否预览
        if (to.query.preview) {
            window.sessionStorage.setItem('preview', to.query.preview);
        }
    }
    const token = getStorage('token') || false;
    if (token) {// 已登录
        heartbeatHandle(to);// 报告心跳
        heartbeatGlobal(to); // 全局心跳
        if (to.matched[0].path === '/login') {
            next('/')
        } else {
            const res = await determineCode(to);// 判断用户绑定
            next(res);
        }
    } else {// 未登录
        // 在免登录白名单，直接进入
        if (routeWhites.indexOf(to.path) !== -1 || routeWhites.indexOf(to.meta.title) !== -1) {
            next();
        } else {
            console.log(123)
            next('/login');
        }
    }
    if (to.meta.isCache) {
        const navStore = useNavStore();
        navStore.setFrom(from);
        navStore.addNav(to);
    }
})

export default router;
