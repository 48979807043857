/**
 * api统一管理
 */
import { get, post } from './http';

// 登录
export const authLogin = (p) => post('/auth/login', p);
// 退出登录
export const authLogout = (p) => get('/auth/logout', p);
// 获取用户登陆报告权限
export const apiAuthList = (p) => get('/api/auth/list', p);
// 修改密码
export const apiUserReset = (p) => post('/api/user/reset', p);
// 找回密码-发送邮件
export const sendMessageEmail = (p) => get('/send/message/email', p);
// 找回密码-发送短信
export const sendMessageSms = (p) => get('/send/message/sms', p);
// 找回密码
export const apiUserFindPwd = (p) => post('/api/user/findPwd', p);
// 图形验证码
export const apiCodeGetCode = (p) => get('/api/code/getCode', p);
// 字典类型列表
export const dicDataGetDicList = (p) => get('/api/dict/getDicList', p);
// 偏好设置-查询所有数据-已选
export const apiLikeLikeList = (p) => get('/api/like/likeList', p);
// 偏好设置-更新偏好
export const apiLikeUpdateLike = (p) => post('/api/like/updateLike', p);
// 用户排名
export const apiUserRank = (p) => get('/api/user/rank', p);
// 资讯附件文件打包下载
export const apiFileConsultZip = () => '/api/file/consult/zip';
// 获取用户是否绑定联系方式
export const apiUserGetUserBindCont = (p) => get('/api/user/getUserBindCont', p);
// 用户-修改联系方式
export const apiUserUpdateUserContactForm = (p) => post('/api/user/updateUserContactForm', p);
// 获取在线用户列表
export const onlineList = (p) => post('/online/list', p);
// 心跳-新增数据
export const apiReportLogAdd = (p) => post('/api/report/log/add', p);
// 新增报告打开日志
export const apiReportLogInsertOpenLog = (p) => post('/api/report/log/insertOpenLog', p);
// 全局心跳
export const apiHeart = (p) => post('/api/heart', p);

/**
 * 菜品星探
 */
// 字典查询
export const getApiDict = (p) => post('/api/dishRating/dict', p);
// 获取列表信息
export const getApiList = (p) => post('/api/dishRating/list', p);
// 获取菜品地域分布
export const getApiRegion = (p) => post('/api/dishRating/region', p);
// 采集季度趋势
export const getApiTrend = (p) => post('/api/dishRating/trend', p);
// 季度top菜品
export const getApiTop = (p) => post('/api/dishRating/top', p);
// 导出
export const getApiExport = () => `/api/dishRating/export`;

/**
 * 品牌星探
 */
// 字典查询
export const getBrandDict = (p) => post('/api/brand/dict', p);
// 获取列表信息
export const getBrandList = (p) => post('/api/brand/list', p);
// 菜品地域分布列表查询
export const getBrandRegion = (p) => post('/api/brand/region', p);
// 季度TOP菜品列表查询
export const getBrandTop = (p) => post('/api/brand/top', p);
// 菜品季度趋势列表查询
export const getBrandTrend = (p) => post('/api/brand/trend', p);
// 导出
export const getBrandExport = () => `/api/brand/export`;

/**
 * 城市潜力
 */
// 字典查询
export const getPotentialDict = (p) => post('/api/potential/dict', p);
// 城市潜力连续性查询
export const getPotentialContinuity = (p) => post('/api/potential/continuity', p);
// 城市潜力一次性查询
export const getPotentialDisposable = (p) => post('/api/potential/disposable', p);
// 导出
export const potentialExport = () => `/api/potential/export`;

/**
 * 原料&风味
 */
// 字典查询
export const apiMaterialFlavorDict = (p) => post('/api/materialFlavor/dict', p);
// 评分信息
export const apiMaterialFlavorList = (p) => post('/api/materialFlavor/list', p);
// 地域分布
export const apiMaterialFlavorRegion = (p) => post('/api/materialFlavor/region', p);
// 季度趋势
export const apiMaterialFlavorTrend = (p) => post('/api/materialFlavor/trend', p);
// 季度top
export const apiMaterialFlavorTop = (p) => post('/api/materialFlavor/top', p);
// 导出
export const apiMaterialFlavorExport = () => `/api/materialFlavor/export`;
// 原料&风味菜品Top300查询
export const apiMaterialFlavorDishTop = (p) => post('/api/materialFlavor/dishTop', p);

/**
 * powerBi
 */
export const apiPowerBiGetEmbedInfo = (p) => get('/api/power_bi/getembedinfo', p);
// 获取过滤器
export const apiPowerBiRlc = (p) => get('/api/power_bi/rlc', p);
// 续token
export const apiHeartPowerbi = (p) => post('/api/heart/powerbi', p);

/**
 * 资讯
 */
// 咨询列表
export const apiConsultGetPageList = (p) => post('/api/consult/getPageList', p);
// 咨询详情
export const apiConsultId = (p) => get('/api/consult/' + p.id);
// 咨询上一条、下一条
export const apiConsultGetPreviAndNext = (p) => post('/api/consult/getPreviAndNext', p);

/**
 * 展会信息
 */
// 分页查询所有数据
export const stActivGetPageList = (p) => post('/api/activ/getActivPageList', p);
// 展会信息一览表
export const apiActivGetActivOverview = (p) => get('/api/activ/getActivOverview', p);
// 展会信息详情
export const apiActivGetActivInfo = (p) => get('/api/activ/getActivInfo', p);
// 展会信息上一条、下一条
export const apiActivGetPreviAndNext = (p) => post('/api/activ/getPreviAndNext', p);
// 首页展会信息列表
export const apiActivGetActivHomeList = (p) => get('/api/activ/getActivHomeList', p);

/**
 * 区县潜力
 */
// 字典查询
export const apiCountyPotentialDict = (p) => post('/api/county/potential/dict', p);
// 城市潜力连续性查询
export const apiCountyPotentialContinuity = (p) => post('/api/county/potential/continuity', p);
// 列表导出(先查询)
export const apiCountyPotentialExport = () => '/api/county/potential/export';
// 城市潜力一次性查询
export const apiCountyPotentialDisposable = (p) => post('/api/county/potential/disposable', p);

/**
 * 客户周报
 */
// 厂家周报年份查询
export const apiFreeWeeklyYears = (p) => post('/api/free/weekly/years', p);
// 周报列表-(按年:全量)
export const apiFreeWeeklyList = (p) => post('/api/free/weekly/list', p);
// 周报板块
export const apiFreeWeeklyModular = (p) => post('/api/free/weekly/modular', p);
// 文章列表(分页)
export const apiFreeWeeklyArticleList = (p) => post('/api/free/weekly/article/list', p);
// 周报详情
export const apiFreeWeeklyId = (p) => post('/api/free/weekly/id', p);
// 文章详情
export const apiFreeWeeklyArticleId = (p) => post('/api/free/weekly/article/id', p);
// 上一条&下一条
export const apiFreeWeeklyArticleNext = (p) => post('/api/free/weekly/article/next', p);
// 获取微信签名
export const apiFreeWeeklyJsSign = (p) => post('/api/free/weekly/js_sign', p);
// 周报板块(树形+统计)
export const apiFreeWeeklyModularStats = (p) => post('/api/free/weekly/modular/stats', p);
// 文章列表(板块关键词关联)
export const apiFreeWeeklyArticleRelated = (p) => post('/api/free/weekly/article/related', p);
// 周报板块详情
export const apiFreeWeeklyModularId = (p) => post('/api/free/weekly/modular/id', p);










