export default [
    {
        path: '/egpm/brand',
        name: 'EgpmBrand',
        meta: { title: '品牌EGPM' },
        component: () => import('../views/egpm-brand/index.vue'),
        redirect: '/egpm/brand/list',
        children: [
            {
                path: 'list',
                name: 'EgpmBrandList',
                meta: { title: '品牌EGPM' },
                component: () => import('../views/egpm-brand/task-list/index.vue')
            },
            {
                path: 'download',
                name: 'EgpmBrandDownload',
                meta: { title: '品牌EGPM-下载记录' },
                component: () => import('../views/egpm-brand/download/index.vue')
            },
            {
                path: 'config',
                name: 'EgpmBrandConfig',
                meta: { title: '品牌EGPM-配置' },
                component: () => import('../views/egpm-brand/config/index.vue')
            },
            {
                path: 'create',
                name: 'EgpmBrandCreate',
                meta: { title: '品牌EGPM-创建任务' },
                component: () => import('../views/egpm-brand/create-task/index.vue')
            },
            {
                path: 'details',
                name: 'EgpmBrandDetails',
                meta: { title: '品牌EGPM-任务详情' },
                component: () => import('../views/egpm-brand/task-details/index.vue'),
                redirect: '/egpm/brand/details/params',
                children: [
                    {
                        path: 'params',
                        name: 'EgpmBrandDetailsParams',
                        meta: { title: '品牌EGPM-任务参数' },
                        component: () => import('../views/egpm-brand/task-details/params/index.vue'),
                    },
                    {
                        path: 'result',
                        name: 'EgpmBrandDetailsResult',
                        meta: { title: '品牌EGPM-任务结果' },
                        component: () => import('../views/egpm-brand/task-details/result/index.vue'),
                        redirect: '/egpm/brand/details/result/single',
                        children: [
                            {
                                path: 'single',
                                name: 'EgpmBrandDetailsResultSingle',
                                meta: { title: '品牌EGPM-任务结果-单期图表' },
                                component: () => import('../views/egpm-brand/task-details/result/single/index.vue'),
                            },
                            {
                                path: 'list',
                                name: 'EgpmBrandDetailsResultList',
                                meta: { title: '品牌EGPM-任务结果-菜品清单' },
                                component: () => import('../views/egpm-brand/task-details/result/list/index.vue'),
                            }
                        ]
                    }
                ]
            }
        ]
    }
]