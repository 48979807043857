export default [
    {
        path: '/egpm',
        name: 'Egpm',
        meta: { title: '菜品EGPM' },
        component: () => import('../views/egpm/index.vue'),
        redirect: '/egpm/list',
        children: [
            {
                path: 'list',
                name: 'EgpmList',
                meta: { title: '菜品EGPM' },
                component: () => import('../views/egpm/task-list/index.vue')
            },
            {
                path: 'download',
                name: 'EgpmDownload',
                meta: { title: '菜品EGPM-下载记录' },
                component: () => import('../views/egpm/download/index.vue')
            },
            {
                path: 'config',
                name: 'EgpmConfig',
                meta: { title: '菜品EGPM-配置' },
                component: () => import('../views/egpm/config/index.vue')
            },
            {
                path: 'create',
                name: 'EgpmCreate',
                meta: { title: '菜品EGPM-创建任务' },
                component: () => import('../views/egpm/create-task/index.vue')
            },
            {
                path: 'details',
                name: 'EgpmDetails',
                meta: { title: '菜品EGPM-任务详情' },
                component: () => import('../views/egpm/task-details/index.vue'),
                redirect: '/egpm/details/params',
                children: [
                    {
                        path: 'params',
                        name: 'EgpmDetailsParams',
                        meta: { title: '菜品EGPM-任务参数' },
                        component: () => import('../views/egpm/task-details/params/index.vue'),
                    },
                    {
                        path: 'result',
                        name: 'EgpmDetailsResult',
                        meta: { title: '菜品EGPM-任务结果' },
                        component: () => import('../views/egpm/task-details/result/index.vue'),
                        redirect: '/egpm/details/result/single',
                        children: [
                            {
                                path: 'single',
                                name: 'EgpmDetailsResultSingle',
                                meta: { title: '菜品EGPM-任务结果-单期图表' },
                                component: () => import('../views/egpm/task-details/result/single/index.vue'),
                            },
                            {
                                path: 'list',
                                name: 'EgpmDetailsResultList',
                                meta: { title: '菜品EGPM-任务结果-菜品清单' },
                                component: () => import('../views/egpm/task-details/result/list/index.vue'),
                            }
                        ]
                    }
                ]
            }
        ]
    }
]