export default [
    {
        path: '/week/:makerId',
        name: 'Week',
        meta: { title: '餐饮资讯周报', keepAlive: true, saveSrollTop: true },
        component: () => import('../views/customer-week-report/week-report/index.vue')
    },
    // {
    //     path: '/week/:makerId/list',
    //     name: 'WeekList',
    //     meta: { title: '餐饮资讯周报' },
    //     component: () => import('../views/customer-week-report/report-list/index.vue')
    // },
    // {
    //     path: '/week/:makerId/report',
    //     name: 'WeekReport',
    //     meta: { title: '餐饮资讯周报' },
    //     component: () => import('../views/customer-week-report/week-report/index.vue')
    // },
    {
        path: '/week/:makerId/plate',
        name: 'WeekPlate',
        meta: { title: '餐饮资讯周报', keepAlive: true, saveSrollTop: true },
        component: () => import('../views/customer-week-report/plate/index.vue'),
    },
    {
        path: '/week/:makerId/details',
        name: 'WeekDetails',
        meta: { title: '餐饮资讯周报' },
        component: () => import('../views/customer-week-report/details/index.vue')
    },
]