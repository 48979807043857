import axios from 'axios'
import { getStorage, clearStorage } from '@/utils'
import { ElMessage, ElLoading } from 'element-plus'
import router from '@/router'

// 设置baseURL
export const baseURL = process.env.VUE_APP_BASE_URL;

// 创建axios实例
const service = axios.create({
    baseURL: baseURL,// axios默认地址
    timeout: 3000 * 1000,//请求超时时间
    headers: {
        'Content-Type': 'application/json; charset=utf-8'
    },
    withCredentials: true // 当跨域请求时发送cookie
})

// 请求拦截器
service.interceptors.request.use((config) => {
    // 如果是预览，将preview放入data中
    if (config.url.startsWith('/api/free/weekly') && window.sessionStorage.getItem('preview')) {
        config.data.preview = window.sessionStorage.getItem('preview');
    }
    // 将token放入headers的签名中
    const token = getStorage('token') || null;
    if (token) config.headers['token'] = token;
    return config;
}, (error) => {
    return Promise.reject(error);
});

// 防止全局的单例loading未关闭
function closeAllLoading() {
    const loadingInstance = ElLoading.service();// 防止全局的单例loading未关闭
    loadingInstance.close();
}

// 响应拦截器
service.interceptors.response.use((response) => {
    if (response.status === 200) {
        const code = Number(response.data.code);
        switch (code) {
            case 0:// 请求成功
                return response;
            case 1:// 请求错误
                ElMessage.error(response.data.msg)
                return response;
            case 401:// 登录失效
                clearStorage();
                closeAllLoading();
                router.replace(`/login`);
                break;
            case 403:// 权限不足
                const currentPath = router.currentRoute?.value?.path;
                if (currentPath !== '/home' || currentPath !== '/') {
                    closeAllLoading();
                    router.replace('/');
                }
                break;
            case 604:// 登录数量达到最大
                return response;
            default:
                ElMessage.error('请求发生错误')
        }
    } else {
        alert('请求失败')
    }
}, (error) => {
    // 错误状态码处理
    return Promise.reject(error);
});

/**
 * get请求
 * @param {String} url [请求地址]
 * @param {Object} params [请求携带参数]
 */
export function get(url, params) {
    return new Promise((resolve, reject) => {
        service.get(url, {
            params: params
        }).then(res => {
            resolve(res.data);
        }).catch(err => {
            reject(err)
        })
    });
}

/**
 * post请求
 * @param {String} url [请求地址]
 * @param {Object} params [请求携带参数]-如需序列化，请使用QS.stringify(params)
 * @param {String} headers [请求头]
 */
export function post(url, params, headers) {
    const postData = {
        url: url,
        method: 'post',
        data: params,
        headers: {
            'Content-Type': headers
        }
    }
    return new Promise((resolve, reject) => {
        service(postData).then(res => {
            resolve(res.data);
        }).catch(err => {
            reject(err)
        })
    });
}
export default service