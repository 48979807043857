export default [
    {
        path: '/',
        name: 'Layout',
        meta: { title: '餐观大数据' },
        component: () => import('../views/layout/index.vue'),
        redirect: '/home',
        children: [
            {
                path: 'home',
                name: 'Home',
                meta: { title: '首页' },
                component: () => import('../views/layout/home/index.vue'),
            },
            {
                path: 'newintro',
                name: 'NewIntro',
                meta: { title: '特别关注', isCache: true, keepAlive: true },
                component: () => import('../views/layout/article/list/index.vue'),
            },
            {
                path: 'industryinfo',
                name: 'Industryinfo',
                meta: { title: '行业资讯', isCache: true, keepAlive: true },
                component: () => import('../views/layout/article/list/index.vue'),
            },
            {
                path: 'valuablebook',
                name: 'ValuableBook',
                meta: { title: '市场宝典', isCache: true, keepAlive: true },
                component: () => import('../views/layout/article/list/index.vue'),
            },
            {
                path: 'article/details/:id',
                name: 'ArticleDetails',
                meta: { title: '文章详情', isCache: true, keepAlive: true },
                component: () => import('../views/layout/article/details/index.vue'),
            },
            {
                path: 'activity',
                name: 'Activity',
                meta: { title: '展会信息', isCache: true, keepAlive: true },
                component: () => import('../views/layout/activity/list/index.vue'),
            },
            {
                path: 'activity/details/:id',
                name: 'ActivityDetails',
                meta: { title: '展会信息详情', isCache: true, keepAlive: true },
                component: () => import('../views/layout/activity/details/index.vue'),
            },
            {
                path: 'password',
                name: 'Password',
                meta: { title: '修改密码', isCache: true, keepAlive: true },
                component: () => import('../views/passport/password-change/index.vue'),
            },
            // {
            //     path: 'like',
            //     name: 'Like',
            //     meta: { title: '关注偏好', isCache: true, keepAlive: true },
            //     component: () => import('../views/passport/like/index.vue'),
            // },
            {
                path: 'bind',
                name: 'BindInfo',
                meta: { title: '绑定邮箱/手机', isCache: true, },
                component: () => import('../views/passport/bind-info/index.vue'),
            }
        ]
    },
    {
        path: '/dish',
        name: 'Dish',
        meta: { title: '餐观星探' },
        component: () => import('../views/dish/index.vue')
    },
    {
        path: '/brand',
        name: 'Drand',
        meta: { title: '餐观鹰眼' },
        component: () => import('../views/brand/index.vue')
    },
    {
        path: '/city',
        name: 'City',
        meta: { title: '餐观鹰眼-城市潜力' },
        component: () => import('../views/city/index.vue')
    },
    {
        path: '/district',
        name: 'District',
        meta: { title: '餐观鹰眼-区县潜力' },
        component: () => import('../views/district/index.vue'),
        redirect: '/district/continue',
        children: [
            {
                path: 'continue',
                name: 'DistrictContinue',
                meta: { title: '餐观鹰眼-区县潜力-连续性数据' },
                component: () => import('../views/district/continue/index.vue'),
            },
            {
                path: 'one',
                name: 'DistrictOne',
                meta: { title: '餐观鹰眼-区县潜力-一次性数据' },
                component: () => import('../views/district/one/index.vue'),
            }
        ]
    },
    {
        path: '/citygrid',
        name: 'CityGrid',
        meta: { title: '餐观鹰眼-城市网格' },
        component: () => import('../views/city-grid/index.vue')
    },
    {
        path: '/materialflavor',
        name: 'MaterialFlavor',
        meta: { title: '餐观星探-原料&风味' },
        component: () => import('../views/material-flavor/index.vue')
    },
    {
        path: '/dishsearch',
        name: 'DishSearch',
        meta: { title: '菜品搜索' },
        component: () => import('../views/dish-search/index.vue'),
        redirect: '/dishsearch/history',
        children: [
            {
                path: 'history',
                name: 'History',
                meta: { title: '菜品搜索-历史报告' },
                component: () => import('../views/dish-search/history/index.vue'),
            },
            {
                path: 'areatemplist',
                name: 'AreaTempList',
                meta: { title: '菜品搜索-自定义大区' },
                component: () => import('../views/dish-search/area-manage/area-temp-list/index.vue'),
            },
            {
                path: 'historymerge',
                name: 'HistoryMerge',
                meta: { title: '菜品搜索-历史合并菜' },
                component: () => import('../views/dish-search/history-merge/index.vue'),
            },
            {
                path: 'areatempdetails',
                name: 'AreaTempDetails',
                meta: { title: '菜品搜索-大区模板详情' },
                component: () => import('../views/dish-search/area-manage/area-temp-details/index.vue'),
            },
            {
                path: 'reportbasic',
                name: 'ReportBasic',
                meta: { title: '菜品搜索-新建报告' },
                component: () => import('../views/dish-search/report-basic/index.vue'),
            },
            {
                path: 'generatereport',
                name: 'GenerateReport',
                meta: { title: '菜品搜索-生成报告' },
                component: () => import('../views/dish-search/generate-report/index.vue'),
            },
            {
                path: 'reportconfig1',
                name: 'ReportConfig1',
                meta: { title: '菜品搜索-报告生成配置' },
                component: () => import('../views/dish-search/report-temps/temp-one/report-config/index.vue'),
            },
            {
                path: 'reportresult1',
                name: 'ReportResult1',
                meta: { title: '菜品搜索-报告结果' },
                component: () => import('../views/dish-search/report-temps/temp-one/report-result/index.vue'),
            }
        ]
    },
    {
        path: '/powerbi',
        name: 'PowerBi',
        meta: { title: 'PowerBi' },
        component: () => import('../views/power-bi/index.vue')
    },
    {
        path: '/login',
        name: 'Login',
        meta: { title: '登录' },
        component: () => import('../views/passport/login/Layout.vue'),
        redirect: '/login/index',
        children: [
            {
                path: 'index',
                name: 'LoginIndex',
                meta: { title: '登录' },
                component: () => import('../views/passport/login/Login.vue'),
            },
            {
                path: 'backpassword',
                name: 'BackPassword',
                meta: { title: '找回密码' },
                component: () => import('../views/passport/login/BackPassword.vue'),
            }
        ]
    },
    {
        path: '/:pathMatch(.*)',// 匹配所有路由
        redirect: '/'
    }
]